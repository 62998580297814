.Home {
  margin-left: 250px;
  margin-top: 70px;
}
body {
  overflow-x: hidden;
  background-color: #f8f9fa;
}
body::-webkit-scrollbar {
  width: 0.4em;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
body::-webkit-scrollbar-thumb {
  background-color:#891c69;
  outline: 1px solid slategrey;
  }
.side-bar::-webkit-scrollbar {
  width: 0.3em;
}

.side-bar::-webkit-scrollbar-thumb {
  background-color:transparent;
  border-top:70px solid #FFF ;
 
  }
  .svg{
    background-color: #f8f9fa;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%23891c69' fill-opacity='0.49'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    clip-path: polygon(0 31%, 89% 100%, 75% 100%, 0 100%);
  }
  .svg2{
    background-color: #f8f9fa;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='152' height='152' viewBox='0 0 152 152'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='temple' fill='%23891c69' fill-opacity='0.49'%3E%3Cpath d='M152 150v2H0v-2h28v-8H8v-20H0v-2h8V80h42v20h20v42H30v8h90v-8H80v-42h20V80h42v40h8V30h-8v40h-42V50H80V8h40V0h2v8h20v20h8V0h2v150zm-2 0v-28h-8v20h-20v8h28zM82 30v18h18V30H82zm20 18h20v20h18V30h-20V10H82v18h20v20zm0 2v18h18V50h-18zm20-22h18V10h-18v18zm-54 92v-18H50v18h18zm-20-18H28V82H10v38h20v20h38v-18H48v-20zm0-2V82H30v18h18zm-20 22H10v18h18v-18zm54 0v18h38v-20h20V82h-18v20h-20v20H82zm18-20H82v18h18v-18zm2-2h18V82h-18v18zm20 40v-18h18v18h-18zM30 0h-2v8H8v20H0v2h8v40h42V50h20V8H30V0zm20 48h18V30H50v18zm18-20H48v20H28v20H10V30h20V10h38v18zM30 50h18v18H30V50zm-2-40H10v18h18V10z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    clip-path: polygon(0 0, 100% 78%, 100% 50%, 100% 0);
  }
  .fit{
    width: fit-content;
  }
  .after::after{
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #891c69;
  }
.Dash {
  display: grid;
  grid-template-columns: 8fr 3fr;
  gap: 20px;
}
.card {
  display: grid;
  grid-template-columns: 8fr 4fr;
}
.min-h {
  min-height: 100vh;
}
.gray {
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgba(128, 128, 128, 0.164);
}
.bank-select {
  display: grid;
  grid-template-columns: 5fr 5fr;
}
.fm input,
.fm select {
  border: 1px solid rgba(128, 128, 128, 0.363);
  padding: 6px;
  border-radius: 6px;
}
.filter {
  min-height: 330px;
}
.g-border{
  border:2px solid #891c69;
}
.wd {
  width: 50%;
}
.modal-grid {
  grid-template-columns: 6fr 4fr;
}
.mg {
  margin-left: 20px;
}
.m-t {
  margin-top: 20px;
}
.card2 {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 20px;
  min-height: 100vh;
}
.grooming-color {
  background-color: #891c69;
}
.grooming-color2:hover {
  background-color: #974d7b;
}
.grooming-color3 {
  background-color: #974d7b;
}
.grooming-text {
  color: #891c69;
}
.border-bottom {
  border-bottom: 2px solid #891c69;
}
.filt {
  filter: brightness(0) invert(1);
}
.w-400 {
  width: 600px;
}
@media screen and (max-width: 769px) {
  .Home {
    margin-left: 0;
  }
  .Dash {
    display: block;
  }
  .card {
    display: block;
  }
}
.white{
  background-color:rgba(128, 128, 128, 0.527);
}
@media screen and (max-width: 1200px) {
  .card {
    display: block;
  }
  .mg {
    margin-left: 0;
    margin-top: 20px;
  }
  .card2 {
    display: block;
    gap: 0px;
  }
  .mar {
    margin-top: 20px;
  }
  .h {
    height: auto;
  }
}
